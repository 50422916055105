body {
    background-color: #f2f6fc;
    color: #456ba8;
}

.container {
    background-color: white;
}

#main {
    background-color: white;
    height: 100vh;
}

.cardButton {
    width: 42px;
    min-height: 34px;
}

.btn-col {
    min-width: 40px;
    padding: 0px;
}

/*increase button size on small devices*/
@media only screen and (max-width: 600px) {
    .cardButton {
        width: 50px;
    }
    .btn-col {
        min-width: 50px;
    }
}

.spades {
    color: #000000;
}

.hearts {
    color: #ff0000;
}

.diamonds {
    color: #0b1bef;
}

.clubs {
    color: #228417;
}

.buffer-row {
    margin-bottom: 20px;
}

.margin-top {
    margin-top: 20px;
}

.margin-left {
    margin-left : 30px;
}

#eval-btn {
    background-color: #6b94d6;
    border-color: #6b94d6;
}

#clear-btn {
    background-color: #de6360;
    border-color: #de6360;
}

.selectedCard {
    background: #f3f3f3;
}

#footer-text {
    color: #54637a;
}

.handCountModifier {
    border: none;
}

.tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #d8d8d8;
}

#add-tooltip > .tooltip-arrow {
    border-top: 5px solid #d8d8d8;
}

#remove-tooltip > .tooltip-arrow {
    border-top: 5px solid #d8d8d8;
}

.capitalize {
    text-transform: capitalize;
}

.handRangeRow {
    margin-bottom: 15px;
}

.rangeBtn {
    height: 40px;
    width: 40px;
}

.suitedRangeBtn {
    background-color: #f5f5f5;
}

.offsuitRangeBtn {
    background-color: #e7f6ff;
}

.pairRangeBtn {
    background-color: #c4dbe8;
}

.rangeRow {
    margin-left: 10px;
}

.selectedRangeBtn {
    background-color: #ffdaef;
}

.removeHandIcon {
    color: red;
}

.rangeModal {
    max-width: 100%;
}

@media (max-width: 500px) {
    .rangeModal {
        overflow-x: auto;
    }
}

.rangeMatrix {
    width: 600px;
}
